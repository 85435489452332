import * as Yup from 'yup';

const phoneRegExp = /^(\d)(?!\1+$)\d{9}$/;

export const optOutFormSchema = (t) => {
    return Yup.object().shape({
        banNumber: Yup.string()
            .required(t(`FIELD_REQUIRED`)),
        optOutReason: Yup.string()
            .required(t(`FIELD_REQUIRED`)),
        newsInFuture: Yup.string()
            .required(t(`FIELD_REQUIRED`))
    });
}