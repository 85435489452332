import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import Input from "@tds/core-input";
import Checkbox from "@tds/core-checkbox";
import Box from "@tds/core-box";
import Radio from "@tds/core-radio";
import Text from "@tds/core-text";
import InputMask from "react-input-mask";
import Select from "@tds/core-select";
import {
  ErrorMessageContainer,
  ErrorMessage,
  InputFieldContainer,
  InputFieldLable,
} from "./css";

import FormCol from "./FormCol";

const getErrorView = (errorMessage) => (
  <ErrorMessageContainer>
    <ErrorMessage>{errorMessage}</ErrorMessage>
  </ErrorMessageContainer>
);

const CustomField = ({
  name,
  label,
  type,
  touched,
  errors,
  tooltip,
  onChange: onFieldChange,
  options,
  placeholder,
  disabled,
}) => {
  return (
    <FormCol>
      <Field name={name}>
        {({ field: { name: fieldName, onBlur, onChange, value } }) => {
          return (
            <>
              {(type === "checkbox" && (
                <Checkbox
                  label={label}
                  type={type}
                  error={touched[name] && errors[name]}
                  feedback={touched[name] && errors[name] && "error"}
                  value={value || ""}
                  checked={value}
                  name={fieldName}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    onFieldChange(e);
                  }}
                />
              )) ||
                (type === "radio" && (
                  <Box tag="fieldset" between={2}>
                    <legend>
                      <Text bold size="medium">
                        {label}
                      </Text>
                    </legend>
                    {options &&
                      options.map((option) => (
                        <Radio
                          key={option}
                          label={option}
                          name={name}
                          value={option}
                          checked={value === option}
                          onChange={onChange}
                        />
                      ))}
                    {touched[name] &&
                      errors[name] &&
                      getErrorView(errors[name])}
                  </Box>
                )) ||
                (type === "mask" && (
                  <InputMask
                    label={label}
                    name={fieldName}
                    mask="(999) 999-9999"
                    value={value || ""}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                  >
                    {({
                      value: maskValue,
                      onChange: onMaskChange,
                      name: maskName,
                    }) => (
                      <Input
                        name={maskName}
                        value={maskValue}
                        onChange={onMaskChange}
                        error={touched[name] && errors[name]}
                        feedback={touched[name] && errors[name] && "error"}
                        type="text"
                        label={label}
                        tooltip={tooltip}
                      />
                    )}
                  </InputMask>
                )) ||
                (type === "select" && (
                  <Select
                    label={label}
                    error={touched[name] && errors[name]}
                    feedback={touched[name] && errors[name] && "error"}
                    name={fieldName}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                    tooltip={tooltip}
                    value={value || ""}
                    options={options}
                    placeholder={placeholder || ""}
                  />
                )) || (
                  <>
                    <Input
                      label={label}
                      type={type}
                      feedback={touched[name] && errors[name] && "error"}
                      name={fieldName}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange(e);
                      }}
                      disabled={disabled}
                      tooltip={tooltip}
                      value={value || ""}
                      options={options}
                    />
                    {touched[name] &&
                      errors[name] &&
                      getErrorView(errors[name])}
                  </>
                )}
            </>
          );
        }}
      </Field>
    </FormCol>
  );
};

CustomField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  tooltip: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
};

CustomField.defaultProps = {
  type: "text",
  tooltip: undefined,
  onChange: () => {},
  options: [],
};

export default CustomField;
