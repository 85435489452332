import styled from 'styled-components';

export const ErrorMessageContainer = styled.div`
    height: 50px; 
    background: #F8DDE3; 
    padding: 1rem; 
    display: flex; 
    align-items: center; 
    margin-top: 5px;
`;

export const InputFieldContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const InputFieldLable = styled.text`
    color: #2a2c2e;
    font-size: 1rem;
    letter-spacing: -0.8px;
    line-height: 1.5;
    word-wrap: break-word;
    font-weight: 700;
    margin-right: 1rem;
`

export const ErrorMessage = styled.div`
    color: #2a2c2e;
    font-size: 14px; 
    font-Weight: 500;
`