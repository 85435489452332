import React, { useState } from "react";
import Heading from "@tds/core-heading";
import Text from "@tds/core-text";
import Box from "@tds/core-box";
import Button from "@tds/core-button";
import FlexGrid from "@tds/core-flex-grid";
import { useTranslation } from "react-i18next";
import { optOutFormSchema } from "../schema";
import FormCol from "../../components/Form/FormCol";
import Field from "../../components/Form/Field";
import axios from "axios";
import Container from "../../components/Container";
import { Formik, Form } from "formik";
import config from "../../config";
import Spinner from "@tds/core-spinner";

const LandingPage = ({ history, location: { search } }) => {
  const params = new URLSearchParams(search);
  const ban = params.get("ban") || "";
  const [t, i18next] = useTranslation();
  const { API_BASE } = config;
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    banNumber: ban || "",
    optOutReason: "",
    newsInFuture: "",
  };

  const onFormSubmitted = (values) => {
    setIsLoading(true);
    const payload = {
      ban: values.banNumber,
      opt_out_reason: values.optOutReason,
      news_in_future:
        values.newsInFuture === t("newsInFutureOptions.optionOne"),
    };
    axios
      .post(`${API_BASE}virtual-care-optout/create`, payload)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.success === 1) {
          history.push(`/${i18next.language}/success`);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Spinner fullScreen label={t("SUBMITTING_INFO")} spinning={isLoading} />
      <Box between={3} inset={3} padding={100}>
        <Heading level="h2">{t("LANDING_PAGE_TITLE")}</Heading>
        <p>{t("LANDING_PAGE_MSG_1")}</p>
        <p>{t("LANDING_PAGE_MSG_2")}</p>
        <Formik
          validationSchema={optOutFormSchema(t)}
          onSubmit={onFormSubmitted}
          enableReinitialize
          initialValues={initialValues}
        >
          {({ handleSubmit, errors, touched, values }) => (
            <Form onSubmit={handleSubmit}>
              <Box between={2} inset={3}>
                <div style={{ width: "50%" }}>
                  <Field
                    name="banNumber"
                    type="text"
                    label={t("BanNumberLabel")}
                    values={values}
                    touched={touched}
                    errors={errors}
                    disabled
                  />
                </div>

                <Field
                  name="optOutReason"
                  type="radio"
                  label={t("optOutReasonLabel")}
                  values={values}
                  options={[
                    t("optOutReasonOptions.optionOne"),
                    t("optOutReasonOptions.optionTwo"),
                    t("optOutReasonOptions.optionThree"),
                    t("optOutReasonOptions.optionFour"),
                  ]}
                  touched={touched}
                  errors={errors}
                />
                <Field
                  name="newsInFuture"
                  type="radio"
                  label={t("newsInFuture")}
                  options={[
                    t("newsInFutureOptions.optionOne"),
                    t("newsInFutureOptions.optionTwo"),
                  ]}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
                <FlexGrid.Row horizontalAlign="center">
                  <FormCol md={6} xs={12}>
                    <Button type="submit">{t(`submitButtonLabel`)}</Button>
                  </FormCol>
                </FlexGrid.Row>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default LandingPage;
