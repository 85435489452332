import React from "react";
import FlexGrid from "@tds/core-flex-grid";
import Box from "@tds/core-box";
import { HeaderBar, LogoBar } from "../../styles/header";

import Logo from "./logo";
import { HeaderLinks, Link } from "../../styles/elements";
import { languageTypes } from "../../config/constants";

const getPageNameWithoutLocale = (pathname) => {
  const [, pageName] = pathname.substr(1, pathname.length).split("/");
  return pageName || window.location.search || "";
};

const Header = (props) => {
  const pageName = getPageNameWithoutLocale(window.location.pathname);

  return (
    <Box>
      <HeaderBar>
        <FlexGrid limitWidth>
          <FlexGrid.Row>
            <Box inset={3}>
              <LogoBar>
                <Logo />
              </LogoBar>
            </Box>
            <HeaderLinks>
              <Link to={`/${languageTypes.en}/${pageName}`}>En</Link>
              &nbsp; | &nbsp;
              <Link to={`/${languageTypes.fr}/${pageName}`}>Fr</Link>
            </HeaderLinks>
          </FlexGrid.Row>
        </FlexGrid>
      </HeaderBar>
    </Box>
  );
};

export default Header;
